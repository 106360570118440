var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"d-flex flex-column gap"},[_c('div',{staticClass:"d-flex flex-column align-items-center py-3 my-3 gap-title"},[(_vm.info.total_orders_count === _vm.info.successful_orders_count)?_c('v-icon',{staticClass:"pb-1",attrs:{"size":"80px","color":"success"}},[_vm._v("mdi-check-circle-outline ")]):_c('v-icon',{staticClass:"pb-2",attrs:{"size":"80px","color":"red"}},[_vm._v("mdi-information-outline ")]),_c('div',{staticClass:"text-h4",class:{
          'success--text':
            _vm.info.total_orders_count === _vm.info.successful_orders_count,
          'text-danger': !(
            _vm.info.total_orders_count === _vm.info.successful_orders_count
          ),
        }},[_vm._v(" "+_vm._s(_vm.info.total_orders_count === _vm.info.successful_orders_count ? _vm.info.message ? _vm.info.message : "All records are successfully processed" : `${this.info.failed_orders_count} of ${_vm.info.total_orders_count} records are failed`)+" ")])],1),_c('div',{staticClass:"d-flex flex-column gap-body"},[(!(_vm.info.total_orders_count === _vm.info.successful_orders_count))?_c('div',{staticClass:"d-flex flex-column ma-4 gap-message text-danger"},_vm._l((_vm.info.failed_orders),function(mes,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(Array.isArray(mes) ? mes[0] : mes)+" ")])}),0):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-end"},[_c('v-btn',{staticClass:"mr-4 mb-4",class:{
            'mt-4': _vm.info.total_orders_count === _vm.info.successful_orders_count,
          },attrs:{"elevation":"0","small":"","color":"info"},on:{"click":_vm.hide}},[_vm._v("ok ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }