<template>
  <v-dialog v-model="dialog" max-width="600">
    <div class="d-flex flex-column gap">
      <div class="d-flex flex-column align-items-center py-3 my-3 gap-title">
        <v-icon
          v-if="info.total_orders_count === info.successful_orders_count"
          class="pb-1"
          size="80px"
          color="success"
          >mdi-check-circle-outline
        </v-icon>
        <v-icon v-else class="pb-2" size="80px" color="red"
          >mdi-information-outline
        </v-icon>
        <div
          class="text-h4"
          :class="{
            'success--text':
              info.total_orders_count === info.successful_orders_count,
            'text-danger': !(
              info.total_orders_count === info.successful_orders_count
            ),
          }"
        >
          {{
            info.total_orders_count === info.successful_orders_count
              ? info.message
                ? info.message
                : "All records are successfully processed"
              : `${this.info.failed_orders_count} of ${info.total_orders_count} records are failed`
          }}
        </div>
      </div>
      <div class="d-flex flex-column gap-body">
        <div
          class="d-flex flex-column ma-4 gap-message text-danger"
          style=""
          v-if="!(info.total_orders_count === info.successful_orders_count)"
        >
          <div v-for="(mes, index) in info.failed_orders" :key="index" class="">
            {{ Array.isArray(mes) ? mes[0] : mes }}
          </div>
        </div>
        <div class="d-flex flex-column align-items-end">
          <v-btn
            class="mr-4 mb-4"
            :class="{
              'mt-4': info.total_orders_count === info.successful_orders_count,
            }"
            elevation="0"
            small
            color="info"
            @click="hide"
            >ok
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";

export default {
  name: "ResultModal",
  props: {},
  computed: {
    info() {
      return this.$store.getters.getReportModalData;
    },
    dialog: {
      get: function() {
        return this.$store.getters.getReportModalState;
      },
      set: function(newValue) {
        if (!newValue) {
          this.$store.commit(SET_REPORT_MODAL_DATA, {});
        }
        this.$store.commit(SET_REPORT_MODAL_STATE, newValue);
      },
    },
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.gap {
  background-color: white;
}

.gap-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgb(237, 237, 237);
}

.gap-title {
}

.gap-message {
  height: auto;
  max-height: 210px;
  overflow-y: scroll;
  font-size: 12px;
}
</style>
